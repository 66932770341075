'use client';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import QRCodeModule from 'qrcode';
import { classNames } from 'src/utils/css';
import { colorWhite, colorText } from 'src/styles/exports.module.scss';
import { WealthsimpleBrand } from 'src/components/common/Icons';
import {
  container as containerClass,
  background as backgroundClass,
  shadow as shadowClass,
  logoBackground as logoBackgroundClass,
  logo as logoClass,
} from './QRCode.module.scss';

export const QRCode = ({ data, withLogo = true, className, withShadow = false, ...rest }) => {
  const [qrCodeSrcDataString, setQrCodeSrcDataString] = useState();

  useEffect(() => {
    const getErrorCorrectionLevel = () => {
      // Ref: https://github.com/soldair/node-qrcode#error-correction-level
      // The error correction is what allows us to overlay the logo
      if (!withLogo) {
        return 'L';
      }
      return data.length < 50 ? 'H' : 'Q';
    };

    QRCodeModule.toDataURL(
      data,
      {
        errorCorrectionLevel: getErrorCorrectionLevel(),
        color: {
          dark: colorText,
          light: colorWhite,
        },
      },
      (err, url) => {
        if (!err) {
          setQrCodeSrcDataString(url);
        }
      }
    );
  }, [data, withLogo]);

  return (
    <div
      className={classNames(className, containerClass)}
      role="img"
      aria-label="Scan the code on your smartphone"
      {...rest}
    >
      <div className={classNames(backgroundClass, withShadow && shadowClass)}>
        <img src={qrCodeSrcDataString} alt="" />
        {withLogo && (
          <div className={logoBackgroundClass}>
            <WealthsimpleBrand size={null} className={logoClass} />
          </div>
        )}
      </div>
    </div>
  );
};

QRCode.propTypes = {
  /**
   * The data to encode in the QR code, usually a link.
   */
  data: PropTypes.string.isRequired,

  /**
   * Whether to display the Wealthsimple logo on the QRCode.
   */
  withLogo: PropTypes.bool,

  /**
   * Whether to render a shadow beneath the Card displaying the QRCode.
   */
  withShadow: PropTypes.bool,
};
