import { usePageAttributesContext } from 'src/components/contexts/PageAttributesContext';
import { useTranslation } from 'src/components/contexts/TranslationContext';
import { getTranslationFromLocale } from 'src/components/contexts/TranslationContext/server';

const DEFAULT_SLUG = 'default';
const getSlugAsTranslationKey = slug => { return Array.isArray(slug) ? slug.join('::') : slug; };
const getPrefix = slug => `wealthsimple-app::${slug}::`;
const getDefaultTranslationKey = key => `wealthsimple-app::${DEFAULT_SLUG}::${key}`;

export const useDynamicDownloadTranslation = key => {
  let { slug = DEFAULT_SLUG } = usePageAttributesContext();
  const { allowDefaultContentFallback = true } = usePageAttributesContext();
  slug = getSlugAsTranslationKey(slug);

  const defaultTranslation = useTranslation(
    getDefaultTranslationKey(key),
    { fallback: null },
  );
  const translation = useTranslation(
    `${getPrefix(slug)}${key}`,
    { fallback: allowDefaultContentFallback ? defaultTranslation : null },
  );

  return translation;
};

// generateMetadata can't use hooks, but our metadata is in translation files,
// so this is `useDynamicDownloadTranslation` implemented as a plain function.
export const getDynamicDownloadTranslation = (key, { locale, translationKeys, allowDefaultContentFallback, slug: _slug = DEFAULT_SLUG }) => {
  let slug = _slug;
  slug = getSlugAsTranslationKey(slug);

  const defaultTranslation = getTranslationFromLocale(
    getDefaultTranslationKey(key),
    { locale, translationKeys, fallback: null }
  );
  const translation = getTranslationFromLocale(
    `${getPrefix(slug)}${key}`,
    { locale, translationKeys, fallback: allowDefaultContentFallback ? defaultTranslation : null }
  );

  return translation;
};
