import(/* webpackMode: "eager" */ "/opt/build/repo/app/[locale]/(app-download)/wealthsimple-app/[[...slug]]/_components/AppDownloadHero/AppDownloadHero.component.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/components/contexts/PageAttributesContext/PageAttributesContext.component.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/components/contexts/TranslationContext/TranslationContext.component.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/components/layout/Header/Header.component.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/components/layout/Header/HeaderContext.component.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/components/layout/PageLayout/PageLayout.layout.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/components/layout/Section/Section.layout.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/tapestry/core/media/media.component.js");
