import { LEGACY_SIGNUP_URL } from 'src/utils/urls';

export const translations = {
  'en-ca': {
    'wealthsimple-app::default::meta::title': 'Download the Wealthsimple app',
    'wealthsimple-app::default::meta::description':
      'Start investing, saving, and spending from your phone.',
    'wealthsimple-app::default::heading': 'Get the Wealthsimple app',
    'wealthsimple-app::default::body-large':
      'Scan the QR code above to start investing, saving, and spending with Wealthsimple.',
    'wealthsimple-app::default::body-small': `Not ready to download the app? [Sign up on the web](${LEGACY_SIGNUP_URL})`,
    'wealthsimple-app::default::disclaimer': `Not all features are available on web. For the best experience we recommend downloading the app.`,
    // PRIVATE EQUITY
    'wealthsimple-app::private-equity::meta::title': 'Download the Wealthsimple app',
    'wealthsimple-app::private-equity::meta::description':
      'Start investing, saving, and spending from your phone.',
    'wealthsimple-app::private-equity::body-large':
      'Scan the QR to complete your application for private equity with the Wealthsimple app.',
    // REFERRALS INVITE
    'wealthsimple-app::referrals-invite::meta::description':
      'Scan the QR code above to open and fund an account, and get rewarded.',
    'wealthsimple-app::referrals-invite::heading': 'Get the app and a reward to invest',
    'wealthsimple-app::referrals-invite::body-large':
      'Scan the QR code above to open and fund an account, and get rewarded.',
    'wealthsimple-app::referrals-invite::disclaimer':
      'By signing up, you agree to Wealthsimple’s [Terms of Use](https://promotions.wealthsimple.com/hc/en-ca/articles/15503591623707) and [Privacy Policy](/legal/privacy).',
    // TRANSFER AN ACCOUNT
    'wealthsimple-app::transfer-an-account::heading': 'Start your transfer',
    'wealthsimple-app::transfer-an-account::body-large':
      'Moving an account? Simply scan the QR code above to begin a transfer in the Wealthsimple app.',
  },
  'fr-ca': {
    'wealthsimple-app::default::meta::title': 'Téléchargez l’appli Wealthsimple',
    'wealthsimple-app::default::meta::description':
      'Commencez à investir, épargner et dépenser depuis votre téléphone.',
    'wealthsimple-app::default::heading': "Téléchargez l'appli Wealthsimple",
    'wealthsimple-app::default::body-large':
      'Numérisez ce code QR pour commencer à investir, épargner et dépenser avec Wealthsimple.',
    'wealthsimple-app::default::body-small': `Vous ne voulez télécharger l’appli maintenant? [Inscrivez-vous à partir du site Web](${LEGACY_SIGNUP_URL})`,
    'wealthsimple-app::default::disclaimer': `Les fonctionnalités ne sont pas toutes offertes sur le Web. Pour avoir la meilleure expérience, nous vous recommandons de télécharger l’application.`,
    // PRIVATE EQUITY
    'wealthsimple-app::private-equity::meta::title': 'Téléchargez l’appli Wealthsimple',
    'wealthsimple-app::private-equity::meta::description':
      'Commencez à investir, épargner et dépenser depuis votre téléphone.',
    'wealthsimple-app::private-equity::body-large':
      'Veuillez numériser le code QR pour terminer votre demande de placement en capital‑investissement dans l’application Wealthsimple.',
    // REFERRALS INVITE
    'wealthsimple-app::referrals-invite::meta::description':
      'Commencez à investir, épargner et dépenser depuis votre téléphone.',
    'wealthsimple-app::referrals-invite::heading':
      'Téléchargez l’appli et obtenez une récompense pour investir',
    'wealthsimple-app::referrals-invite::body-large':
      'Scannez le code QR ci-dessus pour ouvrir et approvisionner un compte, et soyez récompensé.',
    'wealthsimple-app::referrals-invite::disclaimer':
      "En vous inscrivant, vous acceptez les [Conditions d'utilisation](https://promotions.wealthsimple.com/hc/fr-ca/articles/15503591623707-Recommandez-Wealthsimple-%C3%A0-vos-amis-pour-obtenir-une-prime-en-argent) et la [Politique de confidentialité](/legal/privacy) de Wealthsimple.",
    // TRANSFER AN ACCOUNT
    'wealthsimple-app::transfer-an-account::heading': 'Commencez votre transfert',
    'wealthsimple-app::transfer-an-account::body-large':
      "Vous transférez un compte? Numérisez simplement le code QR ci-dessus pour commencer un transfert dans l'application Wealthsimple.",
  },
};
